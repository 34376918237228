body {
  overflow: hidden;
}

.embed-container.instructions {
  min-height: 56.25%;
  height: 100%;
  padding-bottom: 0;
  overflow-y: scroll;
}

@media (orientation: portrait) {
  .embed-container.instructions {
    height: 220px;
    padding-bottom: 0;
    overflow-y: scroll;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sliderCell img {
  width: 100%;
  height: auto;
}

body {
  background: rgb(183,28,28);
  background: linear-gradient(-45deg, rgba(46,125,50,1) 7%, rgba(60,60,60,1) 19%, rgba(60,60,60,1) 68%, rgba(183,28,28,1) 84%);
  height: 100vh;
}

.carousel-wrapper {
  overflow: hidden;
}

.carousel-wrapper > div {
  transform: rotate(90deg);
}

.carousel-wrapper > div > div {
  padding: 8px !important;
}

.carousel-item .sliderCell {
  transform: rotate(-90deg);
}
